import { combineEpics, ofType } from 'redux-observable'
import { concat, from, iif, of } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'

import {
  constants,
  FILTER_LEASE_TYPES_BUSINESS,
  FILTER_LEASE_TYPES_PERSONAL,
  FILTER_VEHICLE_TYPES_VANS,
} from '../../lib/constants'
import { ERRORS } from '../../lib/errors'
import { LOADERS } from '../../lib/loaders'
import {
  sendAlternativeLeadVerificationService,
  sendEnquiryService,
  sendLeadDetailsService,
  sendLeadVerificationService,
  sendPcpLeadDetailsService,
  sendPoorCreditLeadDetailsService,
} from '../../lib/services/userService'
import { checkForUser } from '../../lib/utilities/account'
import { addVatToPrice } from '../../lib/utilities/search'
import { postAnalyticsEvent, sendToDataLayer } from '../actions/event'
import { addError, removeError } from '../reducers/error'
import { addLoader, removeLoader } from '../reducers/loader'
import {
  clearLeadReference,
  sendAlternativeLeadDetails,
  sendAlternativeLeadVerificationCode,
  sendEnquiry,
  sendLeadDetails,
  sendLeadVerificationCode,
  storeEnquiredDeals,
  storeEnquiryReference,
  storeLeadReference,
} from '../reducers/user'
import { selectUserEmail, selectUsersFirstName, selectUsersLastName } from '../selectors/user'

const sendEnquiryEffect = (action$, state$) => {
  const LOADER = LOADERS.sendingEnquiry
  const ERROR = ERRORS.sendingEnquiry

  return action$.pipe(
    ofType(sendEnquiry),
    map(action => action.payload),
    switchMap(params =>
      concat(
        of(addLoader(LOADER)),
        of(removeError(ERROR)),
        from(checkForUser()).pipe(
          switchMap(token => {
            // eslint-disable-next-line camelcase
            const deal_id = params.deal.id
            const firstName = selectUsersFirstName(state$.value)
            const lastName = selectUsersLastName(state$.value)
            const emailAddress = selectUserEmail(state$.value)
            const monthlyPrice =
              params.deal.leaseType === FILTER_LEASE_TYPES_PERSONAL
                ? addVatToPrice(params.deal.monthlyPayment)
                : params.deal.monthlyPayment

            const payload = {
              token,
              // eslint-disable-next-line camelcase
              deal_id,
              phoneNumber: null,
            }

            if (params.phoneNumber) {
              payload.phoneNumber = encodeURI(params.phoneNumber)
            }

            return from(sendEnquiryService(payload)).pipe(
              switchMap(response => {
                return concat(
                  of(removeLoader(LOADER)),
                  of(
                    postAnalyticsEvent({
                      event: constants.eventTypes.enquiryDealPageMessage,
                      payload: {
                        enquiryReference: response.enquiryReference,
                        textValueVehicle: [
                          params.deal.vehicleType === FILTER_VEHICLE_TYPES_VANS ? 'Van' : 'Car',
                          params.deal.manufacturerName,
                          params.deal.rangeName,
                          params.deal.derivativeName,
                          params.deal.fuelTypeName,
                          params.deal.bodyStyleName,
                          params.deal.driveTypeName,
                          params.deal.transmissionTypeName,
                          `${params.deal.doors} Doors`,
                          `${params.deal.seats} Seats`,
                        ],
                        averageMonthlyPayment: params.deal.monthlyPaymentAverage,
                        vehiclePriceTag: params.deal.vehiclePriceTags,
                        vehicleTag: params.deal.vehicleTag,
                        bodyStyle: [params.deal.bodyStyle],
                        driveType: [params.deal.driveType],
                        fuelType: [params.deal.fuelType],
                        manufacturer: [params.deal.manufacturer],
                        model: [params.deal.model],
                        range: [params.deal.range],
                        seats: [params.deal.seats],
                        stockStatus: [params.deal.stockStatus],
                        trim: [params.deal.trim],
                        transmissionType: [params.deal.transmissionType],
                        vehicleType: [params.deal.vehicleType],
                        vehicleId: [params.deal.vehicleId],
                        leaseType: [params.deal.leaseType],
                        term: [params.deal.term],
                        mileage: [params.deal.mileage],
                        initialPaymentInMonths: [params.deal.initialPaymentInMonths],
                        monthlyPayment: params.deal.monthlyPayment,
                        rating: params.deal.rating,
                        doors: [params.deal.doors],
                      },
                    }),
                  ),
                  of(sendToDataLayer(params.dataLayer)),
                  iif(
                    () => params.deal.leaseType === FILTER_LEASE_TYPES_BUSINESS,
                    concat(
                      of(sendToDataLayer(params.businessDataLayer)),
                      of(postAnalyticsEvent(params.businessAnalytics)),
                    ),
                  ),
                  of(
                    sendToDataLayer({
                      event: 'orderComplete',
                      orderValue: monthlyPrice,
                      orderCurrency: 'GBP',
                      // eslint-disable-next-line camelcase
                      orderId: deal_id,
                      ecData: {
                        email: emailAddress,
                        // eslint-disable-next-line camelcase
                        phone_number: payload.phoneNumber,
                        // eslint-disable-next-line camelcase
                        first_name: firstName,
                        // eslint-disable-next-line camelcase
                        last_name: lastName,
                      },
                    }),
                  ),
                  of(storeEnquiredDeals(deal_id)),
                  of(storeEnquiryReference(response.enquiryReference)),
                )
              }),
              catchError(err => {
                if (err.status === 404) {
                  return concat(
                    of(removeLoader(LOADER)),
                    of(
                      addError({
                        key: ERRORS.dealNoLongerExists,
                        message: constants.errorMessages.dealNoLongerExists,
                      }),
                    ),
                  )
                }

                return concat(
                  of(removeLoader(LOADER)),
                  of(
                    addError({
                      key: ERROR,
                      message: constants.errorMessages.sendingEnquiry,
                      fields: err.serverError,
                    }),
                  ),
                )
              }),
            )
          }),
        ),
      ),
    ),
  )
}

const sendLeadDetailsEffect = action$ => {
  const LOADER = LOADERS.sendingCallBack
  const ERROR = ERRORS.sendingCallBack

  return action$.pipe(
    ofType(sendLeadDetails),
    map(action => action.payload),
    switchMap(params => {
      const payload = {
        vehiclePriceId: params.vehiclePriceId,
        emailAddress: params.emailAddress,
        phoneNumber: params.phoneNumber,
        firstName: params.firstName,
        lastName: params.lastName,
        'g-recaptcha-response': params.captcha,
      }

      if (params.queryString) {
        const queryStringKeys = Object.keys(params.queryString)

        queryStringKeys.forEach(key => {
          payload[key] = params.queryString[key]
        })
      }

      return concat(
        of(addLoader(LOADER)),
        of(removeError(ERROR)),
        from(sendLeadDetailsService(payload)).pipe(
          switchMap(response =>
            concat(
              of(storeLeadReference(response.leadReference)),
              of(
                postAnalyticsEvent({
                  event: params.event,
                  payload: {
                    leadReference: response.leadReference,
                    ...params.analyticsData,
                  },
                }),
              ),
              of(removeLoader(LOADER)),
            ),
          ),
          catchError(err => {
            if (err.status === 404) {
              return concat(
                of(removeLoader(LOADER)),
                of(
                  addError({
                    key: ERRORS.dealNoLongerExists,
                    message: constants.errorMessages.dealNoLongerExists,
                  }),
                ),
              )
            } else {
              return concat(
                of(removeLoader(LOADER)),
                of(
                  addError({
                    key: ERROR,
                    message: constants.errorMessages.sendingCallBack,
                    fields: err.serverError,
                  }),
                ),
              )
            }
          }),
        ),
      )
    }),
  )
}

const sendLeadVerificationCodeEffect = action$ => {
  const LOADER = LOADERS.sendingLeadVerificationCode
  const ERROR = ERRORS.sendingLeadVerificationCode

  return action$.pipe(
    ofType(sendLeadVerificationCode),
    map(action => action.payload),
    switchMap(params => {
      const payload = {
        leadReference: params.leadReference,
        code: params.code,
        marketingCommunicationsEmail: params.marketingCommunicationsEmail,
        marketingCommunicationsSms: params.marketingCommunicationsSms,
      }

      return concat(
        of(addLoader(LOADER)),
        of(removeError(ERROR)),
        from(sendLeadVerificationService(payload)).pipe(
          switchMap(response => {
            const isLandingPage = params.event === constants.eventTypes.enquiryDealLandingPage
            const landingPageEvent = {
              event: 'deal-landing-page',
              'enquiry-event': 'deal-landing-page-enquiry',
              'enquiry-action': 'enquiry-made',
              'enquiry-broker': `${params.supplierName}`,
              action: 'button-click',
              label: 'submit-verification-code',
              ecData: {
                email: params.emailAddress,
                // eslint-disable-next-line camelcase
                phone_number: params.phoneNumber,
                // eslint-disable-next-line camelcase
                first_name: params.firstName,
                // eslint-disable-next-line camelcase
                last_name: params.lastName,
              },
            }
            const callbackEvent = {
              event: 'call-back-enquiry',
              'enquiry-event': 'call-back-enquiry',
              'enquiry-action': `call back submitted${
                params.pipelineStockStatus ? ` ${params.pipelineStockStatus}` : ''
              }`,
              'enquiry-broker': `${params.supplierName}`,
              'is-mobile': params.isMobile,
              position: params.position,
              ecData: {
                email: params.emailAddress,
                // eslint-disable-next-line camelcase
                phone_number: params.phoneNumber,
                // eslint-disable-next-line camelcase
                first_name: params.firstName,
                // eslint-disable-next-line camelcase
                last_name: params.lastName,
              },
            }

            return concat(
              of(storeEnquiredDeals(params.vehiclePriceId)),
              of(clearLeadReference()),
              of(storeEnquiryReference(response.enquiryReference)),
              of(sendToDataLayer(isLandingPage ? landingPageEvent : callbackEvent)),
              of(
                postAnalyticsEvent({
                  event: params.event,
                  payload: {
                    enquiryReference: response.enquiryReference,
                    ...params.analyticsData,
                  },
                }),
              ),
              of(
                sendToDataLayer({
                  event: 'orderComplete',
                  orderValue: params.monthlyPrice,
                  orderCurrency: 'GBP',
                  // eslint-disable-next-line camelcase
                  orderId: params.vehiclePriceId,
                  ecData: {
                    email: params.emailAddress,
                    // eslint-disable-next-line camelcase
                    phone_number: params.phoneNumber,
                    // eslint-disable-next-line camelcase
                    first_name: params.firstName,
                    // eslint-disable-next-line camelcase
                    last_name: params.lastName,
                  },
                }),
              ),
              of(removeLoader(LOADER)),
            )
          }),
          catchError(err => {
            if (err.status === 404) {
              return concat(
                of(removeLoader(LOADER)),
                of(clearLeadReference()),
                of(
                  addError({
                    key: ERRORS.dealNoLongerExists,
                    message: constants.errorMessages.dealNoLongerExists,
                  }),
                ),
              )
            } else {
              return concat(
                of(removeLoader(LOADER)),
                of(
                  addError({
                    key: ERROR,
                    message: constants.errorMessages.sendingCallBackVerifyCode,
                  }),
                ),
              )
            }
          }),
        ),
      )
    }),
  )
}

const sendAlternativeLeadDetailsEffect = action$ => {
  const LOADER = LOADERS.sendingAlternativeLead
  const ERROR = ERRORS.sendingAlternativeLead

  return action$.pipe(
    ofType(sendAlternativeLeadDetails),
    map(action => action.payload),
    switchMap(params => {
      const payload = {
        derivative: params.derivative,
        documentFee: Number(params.documentFee),
        emailAddress: params.emailAddress,
        phoneNumber: params.phoneNumber,
        firstName: params.firstName,
        lastName: params.lastName,
        initialPaymentInMonths: params.initialPaymentInMonths,
        initialPaymentTotal: Number(params.initialPaymentTotal),
        leaseType: params.leaseType,
        manufacturer: params.manufacturer,
        mileage: params.mileage,
        model: params.model,
        monthlyPayment: Number(params.monthlyPayment),
        term: params.term,
        'g-recaptcha-response': params.captcha,
      }

      if (params.queryString) {
        const queryStringKeys = Object.keys(params.queryString)

        queryStringKeys.forEach(key => {
          payload[key] = params.queryString[key]
        })
      }

      const alternativeLeadService = payload =>
        params.event === constants.eventTypes.leadPcpLandingPage
          ? sendPcpLeadDetailsService(payload)
          : sendPoorCreditLeadDetailsService(payload)

      return concat(
        of(addLoader(LOADER)),
        of(removeError(ERROR)),
        from(alternativeLeadService(payload)).pipe(
          switchMap(response =>
            concat(
              of(storeLeadReference(response.leadReference)),
              of(
                postAnalyticsEvent({
                  event: params.event,
                  payload: {
                    leadReference: response.leadReference,
                    ...params.analyticsData,
                  },
                }),
              ),
              of(removeLoader(LOADER)),
            ),
          ),
          catchError(err => {
            if (err.status === 404) {
              return concat(
                of(removeLoader(LOADER)),
                of(
                  addError({
                    key: ERRORS.dealNoLongerExists,
                    message: constants.errorMessages.dealNoLongerExists,
                  }),
                ),
              )
            } else {
              return concat(
                of(removeLoader(LOADER)),
                of(
                  addError({
                    key: ERROR,
                    message: constants.errorMessages.sendingCallBack,
                  }),
                ),
              )
            }
          }),
        ),
      )
    }),
  )
}

const sendAlternativeLeadVerificationCodeEffect = action$ => {
  const LOADER = LOADERS.sendingLeadVerificationCode
  const ERROR = ERRORS.sendingLeadVerificationCode

  return action$.pipe(
    ofType(sendAlternativeLeadVerificationCode),
    map(action => action.payload),
    switchMap(params => {
      const payload = {
        leadReference: params.leadReference,
        code: params.code,
      }

      return concat(
        of(addLoader(LOADER)),
        of(removeError(ERROR)),
        from(sendAlternativeLeadVerificationService(payload)).pipe(
          switchMap(() =>
            concat(
              of(clearLeadReference()),
              of(storeEnquiryReference(`alternate_lead_${params.code}`)),
              of(
                sendToDataLayer({
                  event: 'deal-landing-page',
                  'enquiry-event': 'deal-landing-page-alt-enquiry',
                  'enquiry-action': 'alt-enquiry-made',
                  action: 'button-click',
                  label: 'submit-verification-code',
                }),
              ),
              of(
                postAnalyticsEvent({
                  event: params.event,
                  payload: {
                    ...params.analyticsData,
                  },
                }),
              ),
              of(removeLoader(LOADER)),
            ),
          ),
          catchError(err => {
            if (err.status === 404) {
              return concat(
                of(removeLoader(LOADER)),
                of(clearLeadReference()),
                of(
                  addError({
                    key: ERRORS.dealNoLongerExists,
                    message: constants.errorMessages.dealNoLongerExists,
                  }),
                ),
              )
            } else {
              return concat(
                of(removeLoader(LOADER)),
                of(
                  addError({
                    key: ERROR,
                    message: constants.errorMessages.sendingCallBackVerifyCode,
                  }),
                ),
              )
            }
          }),
        ),
      )
    }),
  )
}

export const enquiriesEffect = combineEpics(
  sendEnquiryEffect,
  sendLeadDetailsEffect,
  sendLeadVerificationCodeEffect,
  sendAlternativeLeadDetailsEffect,
  sendAlternativeLeadVerificationCodeEffect,
)
