import { combineEpics, ofType } from 'redux-observable'
import { concat, from, of } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'

import { constants } from '../../lib/constants'
import { ERRORS } from '../../lib/errors'
import { LOADERS } from '../../lib/loaders'
import { removePriceAlertService, addPriceAlertService } from '../../lib/services/userService'
import { addError } from '../reducers/error'
import { addLoader, removeLoader } from '../reducers/loader'
import { removePriceAlert, addPriceAlert, storePriceAlertExists } from '../reducers/user'

export const addPriceAlertEffect = action$ => {
  const LOADER = LOADERS.addingPriceAlert
  const ERROR = ERRORS.addingPriceAlert

  return action$.pipe(
    ofType(addPriceAlert),
    map(action => action.payload),
    switchMap(params =>
      concat(
        of(addLoader(LOADER)),
        from(addPriceAlertService(params)).pipe(
          switchMap(response =>
            concat(of(storePriceAlertExists(response)), of(removeLoader(LOADER))),
          ),
          catchError(() =>
            concat(
              of(removeLoader(LOADER)),
              of(
                addError({
                  key: ERROR,
                  message: constants.errorMessages.addingPriceAlert,
                }),
              ),
            ),
          ),
        ),
      ),
    ),
  )
}

export const removePriceAlertEffect = action$ => {
  const LOADER = LOADERS.removingPriceAlert
  const ERROR = ERRORS.removingPriceAlert

  return action$.pipe(
    ofType(removePriceAlert),
    map(action => action.payload),
    switchMap(params =>
      concat(
        of(addLoader(LOADER)),
        from(removePriceAlertService(params)).pipe(
          switchMap(() => concat(of(storePriceAlertExists(false)), of(removeLoader(LOADER)))),
          catchError(() =>
            concat(
              of(removeLoader(LOADER)),
              of(
                addError({
                  key: ERROR,
                  message: constants.errorMessages.removePriceAlert,
                }),
              ),
            ),
          ),
        ),
      ),
    ),
  )
}

export const priceAlertsEffect = combineEpics(addPriceAlertEffect, removePriceAlertEffect)
