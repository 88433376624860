export const LOADERS = {
  fetchingSearchResults: 'FETCHING_SEARCH_RESULTS',
  fetchingDeal: 'FETCHING_DEAL',
  fetchingVehicle: 'FETCHING_VEHICLE',
  fetchingAvailableLeaseProfiles: 'FETCHING_AVAILABLE_LEASE_PROFILES',
  fetchingSearchFilter: {
    bodyStyles: 'FETCHING_BODY_STYLES_SEARCH_FILTER',
    fuelTypes: 'FETCHING_FUEL_TYPES_SEARCH_FILTER',
    manufacturers: 'FETCHING_MANUFACTURERS_SEARCH_FILTER',
    ranges: 'FETCHING_RANGES_SEARCH_FILTER',
    performance: 'FETCHING_PERFORMANCE_SEARCH_FILTER',
    spec: 'FETCHING_SPEC_SEARCH_FILTER',
    features: 'FETCHING_FEATURES_SEARCH_FILTER',
    transmissionTypes: 'FETCHING_TRANSMISSION_TYPES_SEARCH_FILTER',
    driveTypes: 'FETCHING_DRIVE_TYPES_SEARCH_FILTER',
    doors: 'FETCHING_DOORS_SEARCH_FILTER',
    seats: 'FETCHING_SEATS_SEARCH_FILTER',
    capacity: 'FETCHING_CAPACITY_SEARCH_FILTER',
    wheelbase: 'FETCHING_WHEELBASE_SEARCH_FILTER',
    roofHeight: 'FETCHING_ROOF_HEIGHT_SEARCH_FILTER',
  },
  sendingEnquiry: 'SENDING_ENQUIRY',
  sendingCallBack: 'SENDING_CALL_BACK',
  sendingAlternativeLead: 'SENDING_ALTERNATIVE_LEAD',
  fetchingEnquiries: 'FETCHING_ENQUIRIES',
  fetchingSupplierList: 'FETCHING_SUPPLIER_LIST',
  fetchingHistoricalPriceData: 'FETCHING_HISTORICAL_PRICE_DATA',
  removingSavedEnquiry: 'REMOVING_SAVED_ENQUIRY',
  autoVerifyingUser: 'AUTO_VERIFYING_USER',
  fetchingBestDeals: 'FETCHING_BEST_DEALS',
  fetchingAccountInfo: 'FETCHING_ACCOUNT_INFO',
  savingAccountInfo: 'SAVING_ACCOUNT_INFO',
  updatingMarketingPreferences: 'UPDATING_MARKETING_PREFERENCES',
  fetchingSavedEnquiries: 'FETCHING_SAVED_ENQUIRIES',
  addingPriceAlert: 'ADDING_PRICE_ALERT',
  removingPriceAlert: 'REMOVING_PRICE_ALERT',
  fetchingMultipleSearchFilters: 'FETCHING_MULTIPLE_SEARCH_FILTERS',
  savingDealForRewards: 'SAVING_DEAL_FOR_REWARDS',
  fetchingRewardsHistory: 'FETCHING_REWARDS_HISTORY',
  fetchingLeaseProfilePrices: 'FETCHING_LEASE_PROFILE_PRICES',
  savingSearch: 'SAVING_SEARCH',
  removingSavedSearch: 'REMOVING_SAVED_SEARCH',
  fetchingSavedSearches: 'FETCHING_SAVED_SEARCHES',
  fetchingSavedVehicles: 'FETCHING_SAVED_VEHICLES',
  removingSavedVehicle: 'REMOVING_SAVED_VEHICLE',
  fetchingResultsFromPills: 'FETCHING_RESULTS_FROM_PILLS',
  checkingIfVehicleIsSaved: 'CHECKING_IF_VEHICLE_IS_SAVED',
  fetchingExperiments: 'FETCHING_EXPERIMENTS',
  sendingLeadVerificationCode: 'SENDING_LEAD_VERIFICATION_CODE',
}
