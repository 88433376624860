import { createAction, createSlice } from '@reduxjs/toolkit'

export const INITIAL_STATE = {
  comparison: [],
}

const locoSpaceSlice = createSlice({
  name: 'locoSpace',
  initialState: INITIAL_STATE,
  reducers: {
    addVehicleToComparisonList: (state, action) => {
      if (!state.comparison.includes(action.payload)) {
        state.comparison = [...state.comparison, action.payload]
      } else {
        return state
      }
    },
    removeVehicleFromComparisonList: (state, action) => {
      state.comparison = [...state.comparison.filter(item => item.vehicle.id !== action.payload)]
    },
    clearComparisonList: state => {
      state.comparison = INITIAL_STATE.comparison
    },
    clearLocoSpaceState: () => {
      return INITIAL_STATE
    },
  },
})
export const getVehicleDataForComparison = createAction(
  'locoSpace/getVehicleDataForComparison',
  id => {
    return {
      payload: id,
    }
  },
)

export const {
  addVehicleToComparisonList,
  removeVehicleFromComparisonList,
  clearComparisonList,
  clearLocoSpaceState,
} = locoSpaceSlice.actions

export default locoSpaceSlice.reducer
