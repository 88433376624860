import { combineEpics, ofType } from 'redux-observable'
import { concat, from, of } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'

import { constants } from '../../lib/constants'
import { ERRORS } from '../../lib/errors'
import { LOADERS } from '../../lib/loaders'
import {
  getUserDetailsService,
  getUserMarketingPreferencesService,
  saveUserDetailsService,
  setUserMarketingPreferences,
} from '../../lib/services/userService'
import {
  getVehicleService,
  getVehicleTechnicalDataService,
} from '../../lib/services/vehicleService'
import { checkForUser } from '../../lib/utilities/account'
import { addError, removeError } from '../reducers/error'
import { addLoader, removeLoader } from '../reducers/loader'
import { addVehicleToComparisonList, getVehicleDataForComparison } from '../reducers/locoSpace'
import {
  getAccountInfo,
  getUserMarketingPreferences,
  saveUserDetailsToStore,
  storeUserDetails,
  storeUserMarketingPreferences,
  updateAllMarketingPreferences,
  updateAllUserMarketingPreferences,
} from '../reducers/user'

export const getUserAccountDetailsEffect = action$ => {
  const LOADER = LOADERS.fetchingAccountInfo
  const API_ERROR = ERRORS.fetchingAccountInfo
  const USER_ERROR = ERRORS.checkForUser

  return action$.pipe(
    ofType(getAccountInfo),
    switchMap(() => {
      return concat(
        of(addLoader(LOADER)),
        of(removeError(API_ERROR)),
        of(removeError(USER_ERROR)),
        from(checkForUser()).pipe(
          switchMap(token => {
            return from(getUserDetailsService({ token })).pipe(
              switchMap(response => {
                return concat(of(saveUserDetailsToStore(response)), of(removeLoader(LOADER)))
              }),
              catchError(() => {
                return concat(
                  of(removeLoader(LOADER)),
                  of(
                    addError({
                      key: API_ERROR,
                      message: constants.errorMessages.fetchingAccountInfo,
                    }),
                  ),
                )
              }),
            )
          }),
          catchError(() => {
            return concat(
              of(removeLoader(LOADER)),
              of(
                addError({
                  key: USER_ERROR,
                  message: constants.errorMessages.userCredentials,
                }),
              ),
            )
          }),
        ),
      )
    }),
  )
}

export const saveUserAccountDetailsEffect = action$ => {
  const LOADER = LOADERS.savingAccountInfo
  const API_ERROR = ERRORS.savingAccountInfo
  const USER_ERROR = ERRORS.checkForUser

  return action$.pipe(
    ofType(storeUserDetails),
    map(action => action.payload),
    switchMap(params => {
      return concat(
        of(addLoader(LOADER)),
        of(removeError(API_ERROR)),
        of(removeError(USER_ERROR)),
        from(checkForUser()).pipe(
          switchMap(token => {
            return from(
              saveUserDetailsService({
                token,
                email: params.email,
                firstName: params.firstName,
                lastName: params.lastName,
                phoneNumber: params.phoneNumber,
              }),
            ).pipe(
              switchMap(() => of(removeLoader(LOADER))),
              catchError(({ error }) => {
                return concat(
                  of(
                    addError({
                      key: API_ERROR,
                      message: constants.errorMessages.savingAccountInfo,
                      fields: error.serverError || [],
                    }),
                  ),
                  of(removeLoader(LOADER)),
                  of(
                    saveUserDetailsToStore({
                      phoneNumber: params.previousPhoneNumber,
                    }),
                  ),
                )
              }),
            )
          }),
          catchError(error => {
            return concat(
              of(removeLoader(LOADER)),
              of(
                addError({
                  key: USER_ERROR,
                  message: constants.errorMessages.userCredentials,
                  fields: error.serverError,
                }),
              ),
            )
          }),
        ),
      )
    }),
  )
}

export const getUserMarketingPreferencesEffect = action$ => {
  const LOADER = LOADERS.fetchingAccountInfo
  const API_ERROR = ERRORS.fetchingAccountInfo
  const USER_ERROR = ERRORS.checkForUser

  return action$.pipe(
    ofType(getUserMarketingPreferences),
    switchMap(() => {
      return concat(
        of(addLoader(LOADER)),
        of(removeError(API_ERROR)),
        of(removeError(USER_ERROR)),
        from(checkForUser()).pipe(
          switchMap(token => {
            return from(getUserMarketingPreferencesService({ token })).pipe(
              switchMap(response => {
                return concat(of(storeUserMarketingPreferences(response)), of(removeLoader(LOADER)))
              }),
              catchError(() => {
                return concat(
                  of(removeLoader(LOADER)),
                  of(
                    addError({
                      key: API_ERROR,
                      message: constants.errorMessages.fetchingMarketingPreferences,
                    }),
                  ),
                )
              }),
            )
          }),
          catchError(() => {
            return concat(
              of(removeLoader(LOADER)),
              of(
                addError({
                  key: USER_ERROR,
                  message: constants.errorMessages.userCredentials,
                }),
              ),
            )
          }),
        ),
      )
    }),
  )
}

export const saveUserMarketingPreferencesEffect = action$ => {
  const LOADER = LOADERS.updatingMarketingPreferences
  const API_ERROR = ERRORS.updatingMarketingPreferences
  const USER_ERROR = ERRORS.checkForUser

  return action$.pipe(
    ofType(updateAllUserMarketingPreferences),
    map(action => action.payload),
    switchMap(params => {
      return concat(
        of(addLoader(LOADER)),
        of(removeError(API_ERROR)),
        of(removeError(USER_ERROR)),
        from(checkForUser()).pipe(
          switchMap(token => {
            return from(
              setUserMarketingPreferences({
                token,
                preferences: params,
              }),
            ).pipe(
              switchMap(() => {
                return concat(of(updateAllMarketingPreferences(params)), of(removeLoader(LOADER)))
              }),
              catchError(() => {
                return concat(
                  of(removeLoader(LOADER)),
                  of(
                    addError({
                      key: API_ERROR,
                      message: constants.errorMessages.updatingMarketingPreferences,
                    }),
                  ),
                )
              }),
            )
          }),
          catchError(() => {
            return concat(
              of(removeLoader(LOADER)),
              of(
                addError({
                  key: USER_ERROR,
                  message: constants.errorMessages.userCredentials,
                }),
              ),
            )
          }),
        ),
      )
    }),
  )
}

export const getVehicleDataForComparisonEffect = action$ =>
  action$.pipe(
    ofType(getVehicleDataForComparison),
    map(action => action.payload),
    switchMap(args =>
      from(getVehicleTechnicalDataService({ id: args.id })).pipe(
        switchMap(technicalData => {
          return from(getVehicleService({ id: args.id })).pipe(
            switchMap(response =>
              of(
                addVehicleToComparisonList({
                  vehicle: response,
                  technicalData,
                  currentDeal: args.deal,
                  savedDeal: args.savedDeal,
                }),
              ),
            ),
          )
        }),
      ),
    ),
  )

export const locoSpaceEffect = combineEpics(
  getUserAccountDetailsEffect,
  saveUserAccountDetailsEffect,
  getUserMarketingPreferencesEffect,
  saveUserMarketingPreferencesEffect,
  getVehicleDataForComparisonEffect,
)
